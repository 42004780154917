import {createRouter, createWebHistory} from "vue-router";
import auth from '@/utils/auth.js'

const routes = [
    {
        path: '/',
        redirect: '/login'
    }, 
    {
        path: "/home",
        name: "Home",
        component: () => import ("../pages/HomePage.vue"),
        children: [
            {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import ('../pages/404.vue')
            },
            {
                path: "/tenant",
                name: "Tenant",
                meta: {
                    title: '运营商管理'
                },
                component: () => import("../pages/TenantPage.vue")
            },
            {
                path: "/admin",
                name: "Admin",
                meta: {
                    title: '用户管理'
                },
                component: () => import("../pages/AdminPage.vue")
            },
            {
                path: "/machine",
                name: "Machine",
                meta: {
                    title: '机器管理'
                },
                component: () => import("../pages/MachinePage.vue")
            },
            {
                path: "/order",
                name: "Order",
                meta: {
                    title: '订单管理'
                },
                component: () => import("../pages/OrderPage.vue")
            },
            {
                path: "/message",
                name: "Message",
                meta: {
                    title: '消息管理'
                },
                component: () => import("../pages/MessagePage.vue")
            }
        ]
    }, 
    {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import("../pages/LoginPage.vue")
    },
    {
        path: "/morder",
        name: "MOrder",
        meta: {
            noRequireAuth: true,
            title: '订单列表'
        },
        component: () => import("../pages/mobile/OrderPage.vue")
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    
    if (to.meta.noRequireAuth) { 
        // 路由不需要权限验证，直接访问
        next();
    } else {
        const username = auth.getUsername();
        const token = auth.getToken();

        if (!token && !username && to.path !== '/login') {
            next('/login');
        } else if (to.meta.permission) {
            // 简单模拟管理员权限
            username === 'admin' ? next() : next('/404');
        } else {
            next();
        }
    }
});

export default router;